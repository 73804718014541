.even-name-text-style-for {
  font-family: "Nexa-Bold" !important;
  font-size: 14px !important;
  font-weight: 800 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #8f8f8f !important;
  margin: 0px !important;
  border-radius: none !important;
  padding: 17px 40px 17px 20px !important;
  white-space: nowrap !important;
}
.to-apply-table-border-color {
  border-bottom: 1px solid rgba(41, 41, 41, 1) !important;
}
.main-even-name-text-style-for-padding-margin-name {
  font-family: "Nexa-Bold" !important;
  white-space: nowrap !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  margin: 0px !important;
  color: #ffffff !important;
  --Paper-shadow: none !important;
  padding: 10px 0px 10px 20px !important;

}
.main-even-name-text-style-for-padding-margin-type {
  font-family: "Nexa-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #8f8f8f !important;
  margin: 0px !important;
  white-space: nowrap !important;
}
.css-141kssz-MuiTableCell-root {
  border-bottom: none !important;
}
.main-even-name-text-style-for-padding-margin-type-false-true {
  width: 20px !important;
  height: 20px !important;
}
.export-button {
  display: flex;
  margin-top: 50px !important;
}
.export-button button {
  width: 165px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00d059;
  border: none;
  font-family: "Nexa-Bold";
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  text-align: left;
}

.export-button button:hover {
  background: rgba(0, 208, 89, 0.8);
}
.css-ieghoi {
  padding: 0px !important;
}
.css-z85n5u-MuiTableCell-root {
  padding: 0px !important;
}
.css-ieghoi {
  border-bottom: none !important;
}
.css-aufkh4 {
  padding: 0px !important;
}

.photoroll-photos-container{
    max-width: 1260px;
    padding: 24px 124px 24px 24px;
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
    border: 1px solid;
    margin-top: 50px !important;
border-image-source: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);

box-shadow: 0px 3px 5px 0px #232323 inset;
display: flex;
grid-template-columns: repeat(5,1fr);
gap: 14px;
flex-wrap: wrap;
}

.photo___image1
{
    width: 140px !important;
}
.responsive-div
{
    text-align: center;
    color: #fff;
    padding: 94px 440px;
    border-radius: 8px;
}
@media (max-width: 890px) {
    .photoroll-photos-container{
        grid-template-columns: repeat(4,1fr);
    }
    .responsive-div
    {
        padding: 94px 207px;
    }
}

@media (max-width: 700px) {
    .photoroll-photos-container{
        grid-template-columns: repeat(3,1fr);
    }
    .responsive-div
    {
        padding: 94px 210px;
    }
}
@media (max-width: 600px) {

    @media (max-width: 700px) {
        .photoroll-photos-container{
            grid-template-columns: repeat(3,1fr);
        }
        .responsive-div
        {
            padding: 94px 210px;
        }
    }@media (max-width: 700px) {
        .photoroll-photos-container{
            grid-template-columns: repeat(3,1fr);
        }
        .responsive-div
        {
            padding: 94px 210px;
        }
    }
}
@media (max-width: 540px) {
    .photoroll-photos-container{
        grid-template-columns: repeat(2,1fr);
    }
    .responsive-div
    {
        padding: 94px 100px;
    }
}
@media (max-width: 430px) {
    .photoroll-photos-container{
        padding: 24px !important;
        justify-content: center;
        flex-wrap: wrap;
    }
    .photoroll-photos-container{
        grid-template-columns: repeat(1,1fr);
    }
   
    .try-to-give-width-for-query{
        max-width: 370px !important;
    }
    .responsive-div
    {
        padding: 94px 38px;
    }
}
.UsersUsers{
    width: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-bottom: 100px !important;
    padding-right: 64px !important;
    padding-left: 32px;
}
.UsersButtons{
    display: flex;
    width: 100%;
    gap: 12px;
}
.simpleUserButton{
    text-align: left;
    position:  relative;
    width: 240px;
    height: 48px;
    padding-top: 16px;
    padding-left: 12px;
    font-family: 'Nexa-Regular'!important;
    padding-bottom: 12px;
    background: linear-gradient(
        135deg,
        rgba(15, 15, 15, 1),
        rgba(26, 26, 26, 1)
    );
    color : rgba(255, 255, 255, 1);
    font-weight: 400;
    font-size: 14px;
    line-height: 20.3px;
    border-radius: 8px;
  
}
.simpleUserButton::before {
    content: "";
    border-radius: 8px;
    position: absolute;
    inset: 0;
    border-radius: "44px"; /* Inherit the border-radius from the parent */
    padding: 1px; /* Adjust this value to control the thickness of the border */
    background: linear-gradient(
      135deg,
      rgba(15, 15, 15, 1),
      rgba(26, 26, 26, 1)
     
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
/* .simpleUserButton:hover, */
.simpleUserButton:focus,
.simpleUserButton:active {
    color: rgba(0, 168, 73, 1); /* Change text color on hover/active */
}
/* .simpleUserButton:hover::before, */
/* .simpleUserButton:focus::before, */
.simpleUserButton.active::before {
    content: "";
    border-radius: 8px;
    position: absolute;
    inset: 0;
    padding: 2px; /* Adjust this value to control the thickness of the border */
    background: linear-gradient(
        135deg,
        #13FF7A 0.2%,
        #002B12 50.2%,
        #13FF7A 100.2%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none; /* Ensure ::before does not interfere with button actions */
}

.simpleUserButton.active {
    color: rgba(0, 168, 73, 1); /* Optional: Change text color when selected */
}

.Export{
    background-color: rgba(0, 208, 89, 1);
    border-radius: 100px;
    color: rgba(3, 3, 3, 1);
    font-family: 'Nexa-Bold';
    font-weight:800;
    font-size: 14px;
    line-height: 19.6px;
    width: 165px;
    text-align: center;
    padding-top: 13px;
    padding-bottom: 11px;
    height: 44px;
    border: none;
    margin-bottom: 60px !important;
 
}

@media (max-width: 760px) {
    .simpleUserButton {
        font-size: 12px !important;
       }
    .css-1is5qbi{
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
}
@media(max-width:475px)
{
    .UsersUsers{

        padding-right: 20px !important;
        padding-left: 20px;
        
    }
}
@media (max-width: 430px) {
.Export {
    width: 254px !important;
    
}}
@media (max-width: 375px) {
    .simpleUserButton {
     font-size: 10px !important;
    }
}
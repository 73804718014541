.even-name-text-style-for {
  font-family: "Nexa-Bold" !important;
  font-size: 14px !important;
  font-weight: 800 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #8f8f8f !important;
  margin: 0px !important;
  border-radius: none !important;
  padding: 17px 0px 17px 20px !important;
  white-space: nowrap !important;
}
.user_table_container {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.user_table_container::-webkit-scrollbar {
  display: none !important;
}

/* ////////////////////////////////////////////////// */



/* /////////////////////////////////////////////////// */

.to-apply-table-border-color {
  border-bottom: 1px solid rgba(41, 41, 41, 1) !important;
}
.main-even-name-text-style-for-padding-margin-name {
  font-family: "Nexa-Bold" !important;
  white-space: nowrap !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  margin: 0px !important;
  color: #ffffff !important;
  --Paper-shadow: none !important;
  padding: 10px 30px 10px 20px !important;
}
.main-even-name-text-style-for-padding-margin-type {
  font-family: "Nexa-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #8f8f8f !important;
  margin: 0px !important;
}
.css-141kssz-MuiTableCell-root {
  border-bottom: none !important;
}
.main-even-name-text-style-for-padding-margin-type-false-true {
  width: 20px !important;
  height: 20px !important;
}
.export-button {
  display: flex;
  margin-top: 50px !important;
  padding-bottom: 0px !important;
}
.export-button button {
  width: 165px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00d059;
  border: none;
  font-family: "Nexa-Bold";
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  text-align: left;
  color: #030303 !important;
}
.export-button button:hover {
  background: rgba(0, 208, 89, 0.8);
}
.personal__user__table_div {
  display: flex;
  padding: 20px 16px;
  justify-content: space-between;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);

  border: 1px solid transparent;
  border-image: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  box-shadow: inset 0px 3px 5px 0px #232323;
  border-radius: 8px;
  margin-bottom: 20px !important;
}
.search__user_input {
  background: transparent;
  border: none;
  width: 100%;
  color: #bfbfbf;
  font-family: Nexa;
}
.search__user_input::placeholder {
  font-family: Nexa;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #bfbfbf;
  border: none
}
.search__user_input:focus
{
 outline: none;
 font-family: Nexa;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #bfbfbf;
}
.personal__user__table_div_1
{
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%
}
.css-1f4ultx-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover
{
  background-color: #00d059 !important;
}
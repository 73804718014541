.box-main-both-head-and-body{
    max-width: 1260px !important;
}
.mtr-owned-center-part{
    display: flex;
    flex-direction: column;
    max-width: 1260px !important;
    padding: 24px 0px 24px 24px;
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
    box-shadow: 0px 3px 5px 0px #232323 inset;
    border: 1px solid;
   margin-top: 50px !important;
    border-image-source: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    gap: 10px;
    
}
.mtr-owned-center-part p{
    font-family:  "Nexa-Regular" !important;
font-size: 14px;
font-weight: 400;
line-height: 18.9px;
text-align: left;
color: #8F8F8F;
margin: 0px !important;
}
.smjh-se-bahr-q {
    margin: 0px !important; 
font-size: 16px;
font-weight: 700;
line-height: 21.6px;
text-align: left;
color: #FFFFFF;
letter-spacing: 0.1em !important;
font-family:  "Nexa-Bold" !important;
}
.css-141kssz-MuiTableCell-root{
    padding: 0px !important;
}
.Premier__League__text
{
    font-family: "Nexa-Bold";
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #E6E6E6;
}
.ticket__type__detail__main__container_top_attendence
{
    display: block;
    margin-top: 50px !important;
}
.main-even-name-text-style-for_sales-padding-margin-name__sales2
{
    font-family:"Nexa-Regular"!important ;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20.3px !important;
    text-align: left !important;
    color: #ffffff !important;
}
.Organizer_detail_Follower_Main_____{
    background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%) !important;

}
.personal__user__table_div1 {
    display: flex;
    padding: 20px 16px;
    justify-content: space-between;
    background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  
    border: 1px solid transparent;
    border-image: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0.06) 100%
    );
    box-shadow: inset 0px 3px 5px 0px #232323;
    border-radius: 8px;
    margin-bottom: 20px !important;
    margin-top: 50px !important;
  }
  .Organizer_detail_Follower_Main___Body2
  {
    font-family: "Nexa-Regular" !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20.3px !important;
    text-align: left !important;
    color: #8f8f8f !important;
    margin: 0px !important;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) !important;
    border-bottom: 1px solid #292929 !important;
    padding-left: 30px !important;
  }
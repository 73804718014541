.festivals__multitaks__maon__container
{
    padding: 75px 100px;
    display: flex;
    flex-direction: column;
}
.main-even-name-text-style-for_sales-padding-margin-name__sales2
{
    font-family:"Nexa-Regular" !important ;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20.3px !important;
    text-align: left !important;
    color: #ffffff !important;
}
.festivals__multitask__heading__container
{
    display: flex;
    flex-direction: column;
    gap:24px
}
.top-buttons___new_try__sales
{
    display: flex;
    flex-direction: row;
    gap:12px;
    flex-wrap: wrap;
}
.top-buttons___new_try__sales button
{
    max-width: 400px;
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    /* justify-content: center; */
    background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
    border: 1px solid;
    border-image-source: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    color: #ffffff;
    font-family: "Nexa-Regular" !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
    text-align: left;
    letter-spacing: 0.05em !important;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
}
.premiur__text__main
{
    font-family: Nexa-Bold;
font-size: 18px;
font-weight: 700;
line-height: 21.6px;
letter-spacing: -0.04em;
text-align: left;
color: #e6e6e6;
margin-top:50px !important

}
.ticket__type__detail__main__container_top
{
    display: flex;
    flex-direction: column;
    gap:50px;
    margin-top: 50px !important;
}
@media(max-width:700px)
{
    .festivals__multitaks__maon__container
{
    padding: 50px 50px;

}
}
@media(max-width:440px)
{
    .festivals__multitaks__maon__container
{
    padding: 50px 20px;

}
}
.Organiser_Detail_HostedEvents_Sales_Analytics_Main{

    display: flex;
    flex-direction: column;
gap: 32px ;
padding: 75px 46px 74.67px 60px;
background: #030303;
max-width: 100%;


}



.Organizer_detail_Follower__Btn{
    margin-top: 50px !important;
    width: 165px ;
height: 44px;
border-radius: 100px !important;
background: #00D059 !important;
font-family: 'Nexa-Bold' !important;
font-size: 14px !important;
font-weight: 800 !important;
line-height: 19.6px !important;
text-align: center !important;
color: #030303 !important;
border: none !important;

}
.Organizer_detail_Follower__Btn:hover{
    background: rgba(0, 208, 89, 0.8);
}
@media (max-width: 680px) {
    .SalesAnalytics_table1___main_Firstttt____ppp {
        font-size: 25px !important;
        gap: 45px !important;
    }
}
@media (max-width: 570px) {
    .SalesAnalytics_table1___main_Firstttt____ppp {
        font-size: 15px !important;
    }
    .Organiser_Detail_HostedEvents_Sales_Analytics_Main {
        padding:40px  25px !important;
        gap: 50px !important;
    }
    
}
@media (max-width: 460px) {
  
    .SalesAnalytics_table1___main {
        padding: 24px 10px !important;
    }
}
.Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image{
    display: flex;
    gap: 16px !important;
    align-items: center !important;
}
.Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image h2{
    font-family:"Nexa-Bold";
font-size: 36px;
font-weight: 800;
line-height: 41.4px;
letter-spacing: -0.04em;
text-align: left;
color: #E6E6E6;
margin: 0px !important;
margin-top:13px !important
}
@media (max-width: 430px) {
.Organizer_detail_Follower__Btn{
    max-width: 430px !important;
  }}
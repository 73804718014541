.EventTicket-to-give-max-width-attendeed-main {
  max-width: 1240px;
}
.main-even-name-text-style-for-padding-margin-type-to-give-color {
  font-family: "Nexa-Bold" !important;
  white-space: nowrap !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20.3px !important;
  text-align: left;
  color: #00d059 !important;
  margin: 0px !important;
}
.hosted__table_container {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.hosted__table_container::-webkit-scrollbar {
  display: none !important;
}
.custom-dropdown-button {
  font-family: Nexa !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #8f8f8f !important;
}

.dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  font-family: Nexa !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #8f8f8f !important;
}
.dropdown-with-zindex {
  z-index: 9999;
}
.custom-dropdown-button .dropdown-toggle::after {
  display: none;
}

.dropdown-menu.show {
  background-color: #030303;
  transform: translate3d(0px, 5px, 0px) !important;
}
.dropdown__text {
  font-family: Nexa;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  text-align: left;
  color: #8f8f8f !important;
  border-bottom: 1px solid #292929 !important;
}

.signed_in_main_container_auth {
  background: url("/public/Images/Signed_in_main/Auth.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
  min-height: 100vh !important;
}

.sign-in-main-content {
  background-repeat: no-repeat;
  width: 480px !important;
  background: url("/public/Images/Signed_in_main/Modal.png");
  color: #ffffff;
  background-size: 100% 100%;
  text-align: center;
}

.sign-in-title {
  color: #ffffff;
  font-family: "Nexa-Bold" !important;
  font-size: 24px !important;
  font-weight: 800 !important;
  line-height: 27.6px !important;
  letter-spacing: -0.02em !important;
  text-align: left !important;
  margin: 0px !important;
}

.highlight {
  color: #00d059;
}

.email-and-password-main-container {
  padding: 34px 24px 24px 24px !important;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #292929;
}

.sign-in-title-main-container {
  padding: 16px 24px;
}
.sign-in-middle-part{
  max-width: 100%;
  padding: 16px 20px 16px 12px;
  border: 1px solid;
  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  border-radius: 8px;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  overflow: hidden;
}

 .sign-in-middle-part2 {
  margin-top: 20px !important;
  max-width: 100%;
  padding: 16px 20px 16px 12px;
  border: 1px solid;
  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  border-radius: 8px;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  overflow: hidden;
}

.sign-in-middle-part label, .sign-in-middle-part2 label {
  font-family: "Nexa-Bold";
  font-size: 12px;
  font-weight: 700;
  line-height: 16.2px;
  text-align: left !important;
  color: #8f8f8f;
  margin: 0px !important;
}

.email-input-container {
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.email-input-container input {
  flex: 1;
  background: none;
  border: none;
  outline: none;
  font-family: Nexa-bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF;
}

.email-input-container input::placeholder {
  font-family: Nexa-bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  margin: 0px !important;
}

.auth-screen-password-part-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}

.password-label {
  font-family: Nexa-bold;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.2px;
  text-align: left;
  margin: 0px !important;
  color: #8f8f8f;
}

.password-input-container {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.password-input {
  background-color: transparent;
  border: none;
  font-family: Nexa-bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF !important;
  margin: 0px;
  outline: none;
  width: 100%;
}

.password-input::placeholder {
  background-color: transparent;
  font-family: Nexa-bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF !important;
}

.password-icon {
  cursor: pointer;
}
.password-input::-ms-reveal {
  display: none;
}
.password-input::-ms-clear {
  display: none;
}


.lock-icon {
  width: 20px;
  height: 20px;
}

.give-gap-to-lock-and-view-image {
  display: flex;
  gap: 8px;
  align-items: center;
}

.Forgot-your-password {
  position: relative;
  top: 8px;
  font-family: Nexa-bold;
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  text-align: left;
  color: #bfbfbf;
  cursor: pointer;
}

.Forgot-your-password-border-main {
  display: flex;
  flex-direction: column;
}

.Forgot-your-password-border-bottom {
  width: 158px;
  border: 0.3px solid rgba(255, 255, 255, 0.253);
  margin-top: 3px !important;
}

.log-in-button-bottom-in-sign-in {
  /* max-width: 432px; */
  width:100%;
  height: 52px;
  border-radius: 200px;
  background: #00d059;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nexa-bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: #030303;
  border:none
}
.log-in-button-bottom-in-sign-in:hover
{
  background: rgba(0, 208, 89, 0.8);
}
.log-in-button-bottom-in-sign-in-main {
  padding: 16px 24px !important;
}

.sign-in-middle-part:focus, .sign-in-middle-part2:focus {
  border: 1px solid #13ff7a !important;
}

@media (max-width: 515px) {
  .sign-in-main-content {
    max-width: 470px;
  }
}

@media (max-width: 482px) {
  .signed_in_main_container_auth {
    background: none;
  }
  .sign-in-main-content {
    max-width: 480px !important;
    justify-content: space-between;
    min-height: 100vh !important;
    padding: 8px !important;
  }
  .natlogo-display-hide {
    display: none;
  }
}

@media (max-width: 480px) {
  .signed_in_main_container_auth {
    background-size: cover;
    background-repeat: no-repeat;
  }
 
  .sign-in-main-content {
    width: 100% !important;
    background: url('/public/Images/Signed_in_main/Responsive\ -\ Sign\ In.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  .sign-in-title {
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .sign-in-title-main-container {
    padding: 20px 16px;
    display: flex;
    align-items: flex-end;
  }

  .email-and-password-main-container {
    padding: 34px 11px !important;
    margin-top: 50px !important;
    border-bottom: none !important;
  }

  .sign-in-middle-part, .sign-in-middle-part2 {
    padding: 12px;
    max-width: 100% !important;
  }

  .Forgot-your-password {
    line-height: 18px;
  }

  .Forgot-your-password-border-main {
    padding: 8px 0;
  }

  .log-in-button-bottom-in-sign-in {
    max-width: 100%;
    height: 48px;
  }

  .log-in-button-bottom-in-sign-in-main {
    padding-left: 16px !important;
    padding-right: 16px !important;
   
  }

  .give-gap-to-lock-and-view-image {
    gap: 4px;
  }


  .log-in-button-bottom-in-sign-in-main {
    padding-top: 230px !important;
    padding-bottom: 68px !important;

  }
}

.gradient-border {
  display: inline-block;
  padding: 1px;
  border-radius: 8px;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
}

.content-container {
  border-radius: 8px;
}

.sign-in-middle-part::after, .sign-in-middle-part2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  padding: 1px;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.sign-in-middle-part.active-border::after, .sign-in-middle-part2.active-border::after {
  background: linear-gradient(135deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%);
  
}

.sign-in-middle-part::after, .sign-in-middle-part2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  padding: 1px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none; /* Allow interaction with underlying elements */
}

.sign-in-middle-part.active-border::after, .sign-in-middle-part2.active-border::after {
  background: linear-gradient(135deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%);
  pointer-events: none; /* Prevent overlay from blocking input clicks */
}


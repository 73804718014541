.new_updated_payout_to_give_margen_________ {
  margin-top: 50px !important;
  padding-bottom: 30px !important;
  padding-right: 64px !important;
  padding-left: 32px !important;
}
.Organizer_detail_Follower_Main_____ {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.Organizer_detail_Follower_Main_____::-webkit-scrollbar {
  display: none !important;
}
.empty__state__main__cintainer__1 {
  text-align: center;
  color: #fff;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  border-bottom: none;
  border-radius: 8px;
  width: 100%;
  height: 257px;
  border-bottom: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 475px) {
  .new_updated_payout_to_give_margen_________ {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

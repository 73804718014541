.qa__payout__main__container
{
    padding: 0px 64px 0px 32px !important;
}
@media(max-width:475px)
{
    .qa__payout__main__container
{
    padding: 0px 20px 0px 20px !important;
}
}
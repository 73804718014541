/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
*{
  margin: 0px !important;
}
@font-face {
  font-family: "Nexa";
  src: url("../src/Assets/Font/Nexa-Font/NexaRegular.otf") format("truetype");
}
@font-face {
  font-family: "Nexa-Bold";
  src: url("/public/FontFamily/Nexa\ Bold.otf") format("truetype");
}

@font-face {
  font-family: "Nexa-Regular";
  src: url("/public/FontFamily/Nexa\ Regular.otf");
}
body{
  background: #030303 !important;
}
.Toastify__toast-body 
{
  gap: 10px !important
}
.h6, h6 {
  font-family: "Nexa-Bold" !important;
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 20px !important;
  text-align: left !important;
  color: #FFFFFF !important;
  display: flex !important;
  justify-content: center !important;
  white-space: nowrap !important;
}

.table__iner__content__wraper
{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 32px;
}
.box_uper_text
{
    font-family: "Nexa-Regular";
font-size: 14px;
font-weight: 400;
line-height: 19.6px;
text-align: left;
color: #D9D9D9;
margin-bottom: 8px !important;
}
.box_uper_text2
{
    font-family: 'Nexa-Bold';
font-size: 32px;
font-weight: 700;
line-height: 35.84px;
letter-spacing: -0.04em;
text-align: left;
color: #00D059;
}
.box_uper_text-wraper
{
    width: 100%;
}
.table__uper__content__wraper
{
    margin-top: 24px !important;
    padding: 24px !important;
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
    border: 1px solid;
    border-image-source: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    box-shadow: 0px 3px 5px 0px #232323 inset;

    
}
.private_event_ticketing_main
{
    padding:75px 100px !important ;
}
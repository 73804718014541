.photoroll-photos-container-eventliked {
  max-width: 952px;
  padding: 24px 24px 24px 24px;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  border: 1px solid;
  margin-top: 50px !important;
  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  display: grid;
  grid-template-columns: repeat(3,1fr);
  box-shadow: 0px 3px 5px 0px #232323 inset;

  gap: 20px;
}
 .eventliked-main-images{
    background: url('/public/Images/LikedEvents/launch.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 288px;
    height: 288px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #424242;
    border-radius: 8px;
}
.eventliked-main-images-2{
    border-radius: 8px;
    background: url('/public/Images/LikedEvents/fashion.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 288px;
    height: 288px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #424242
}
.eventliked-main-images-3{
    border-radius: 8px;
    background: url('/public/Images/LikedEvents/womenday.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 288px;
    height: 288px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #424242
}
.eventliked-main-images-4{
    background: url('/public/Images/LikedEvents/launch.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 8px;
    width: 288px;
    height: 288px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #424242
}
.eventliked-main-images-5{
    background: url('/public/Images/LikedEvents/fashion.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 8px;
    width: 288px;
    height: 288px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #424242
}
.eventliked-main-images-6{
    background: url('/public/Images/LikedEvents/womenday.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 288px;
    height: 288px;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #424242
}
.NAITRAM-Launch-Party{
    display: flex;
    flex-direction: column;
    width: 100%;
   
}
.NAITRAM-Launch-Party h2{
    font-family: "Nexa-Bold" !important;
font-size: 24px;
font-weight: 800;
line-height: 27.6px;
letter-spacing: -0.04em;
text-align: left;
color: #FFFFFF;
margin: 0px !important;
}

.NAITRAM-Launch-Party p{
    font-family: "Nexa-Bold" !important;
font-size: 11px;
font-weight: 700;
line-height: 16.5px;
text-align: left;
color: #FFFFFF;

margin: 0px !important;
}

@media (max-width: 1140px) {
    .photoroll-photos-container-eventliked{
        gap: 1px;
    }
    .eventliked-main-images{
        width: 250px;
    }
    .eventliked-main-images-2{
        width: 250px;
    }
    .eventliked-main-images-3{
        width: 250px;
    }
    .eventliked-main-images-4{
        width: 250px;
    }
    .eventliked-main-images-5{
        width: 250px;
    }
    .eventliked-main-images-6{
        width: 250px;
    }
}

@media (max-width: 925px) {
    .photoroll-photos-container-eventliked{
     flex-wrap: wrap !important;
     display: flex;
     gap: 20px;
     justify-content: center;
    }
  
}
@media (max-width: 430px) {
    .photoroll-photos-container-eventliked{
        gap: 20px;
    }
    .eventliked-main-images{
        width: 250px;
        height: 230px;
    }
    .eventliked-main-images-2{
        width: 250px;
        height: 230px;
    }
    .eventliked-main-images-3{
        width: 250px;
        height: 230px;
    }
    .eventliked-main-images-4{
        width: 250px;
        height: 230px;
    }
    .eventliked-main-images-5{
        width: 250px;
        height: 230px;
    }
    .eventliked-main-images-6{
        width: 250px;
        height: 230px;
    }
}
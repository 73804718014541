.SalesAnalytics_table1___MAIN___111{
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) !important;
    box-shadow: none !important;
    margin-top: 32px !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
}


.SalesAnalytics_table1___main_Firstttt____ppp{
    font-family: 'Nexa-Bold' !important;
font-size: 32px;
font-weight: 800;
line-height: 35.84px;
letter-spacing: -0.04em;
text-align: left;
color: #FFFFFF;
margin: 0px !important;
}
.personal-info-detail-container-----FOLLOWING{
    max-width: 1260px !important;
    padding: 0px;
}
@media(max-width:475px)
{
    .personal-info-detail-container-----FOLLOWING
    {
        padding: 20px;
    }
}
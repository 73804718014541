/* SalesAnalystGraph.css */

/* Container style */
.sales-analyst-container {
  /* padding: 16px; */
  height: 100%;
}

.sales-header {
  display: flex;
  justify-content: space-between;
}
.sales-header1
{
  background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);

}
.sales-header-text {
  font-size: 30px;
  font-weight: bold;
}

.sales-header-span {
  font-family: Nexa-Bold;
  font-size: 32px;
  font-weight: 800;
  line-height: 35.84px;
  text-align: left;
  color: #FFFFFF
  
}

.chart-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px; /* Ensure the chart has a defined height */
  transform: scale(1);
}

.chart-wrapper-lg {
  width: 100%;
  max-width: 100%;
}

.bar-chart-container {
  width: 100%;
  height: 100%;
}

.bar-chart-container-lg {
  width: 120%;
}

.label-list {
  font-size: 11px;
  font-weight: 700;
  fill: #0FFF77;
  position: insideBottom;
}

.tooltip-container {
  background: #1A202C; /* Adjusted for gradient-slate */
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  padding: 4px;
}

.tooltip-label {
  color: white;
}
.x-axis-ticks text {
  font-family: "Nexa-Bold";
  font-weight: 400;
  font-size: 30px;
  fill: #ffffff; /* Color for the tick text */
  color: #ffffff; /* Ensure color is consistent */
}
@media(max-width:500px)
{
  .x-axis-ticks text {
    font-size: 20px;
  }

}
.Organiser_Detail_Liked_Events_Main{
    margin-top: 50px !important;
    padding: 24px ;
    display: flex ;
    gap: 20px ;
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
display: grid;
grid-template-columns: repeat(4, 1fr);
max-width: 1260px;

}
.No_Events_Message{
    font-family: Nexa-Bold;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #FFFFFF;
    margin: 0px !important;
    
}

.Organiser_Detail_Liked_Events_Main_1{

    height: 288px;
    /* min-width: 288px;
    width: 288px; */
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: end;
gap: 11px;
padding: 0px 24px 22px  24px;
background-repeat: no-repeat;
background-size: 100%  100%;
background-position: top;
border: 1px solid #424242



}


.Organiser_Detail_Liked_Events_Main_1_P1{
    font-family: 'Nexa-Bold' !important;
font-size: 24px;
font-weight: 800;
line-height: 24px;
letter-spacing: -0.04em;
text-align: left;
color: #FFFFFF;
margin: 0px !important;



}
.Organiser_Detail_Liked_Events_Main_1_P2{
    font-family: 'Nexa-Bold' !important;
font-size: 11px;
font-weight: 700;
line-height: 16.5px;
text-align: left;
color: #FFFFFF;
margin: 0px !important;

}



@media(max-width:1170px){
    .Organiser_Detail_Liked_Events_Main{
        max-width: unset;
        display: flex;
        flex-wrap: wrap;

    }
}

@media(max-width:645px){
    .Organiser_Detail_Liked_Events_Main{
        max-width: unset;
        display: grid;

       grid-template-columns: repeat(2,1fr);

    }
.Organiser_Detail_Liked_Events_Main_1{
    width: 100%;
    min-width: unset;
    background-size: cover;
    
}

.Organiser_Detail_Liked_Events_Main_1_P1{
    font-size: 22px;
    line-height: 24px;


}
.Organiser_Detail_Liked_Events_Main_1_P2{
    font-size: 10px;
    line-height: 14px;

}

}

@media(max-width:515px){
    .Organiser_Detail_Liked_Events_Main{
        display: flex;
        flex-wrap: wrap;
    }
}
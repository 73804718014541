

.SalesAnalytics_table1___main{
    display: flex;
    flex-direction: column;
    max-width: 904px;

padding: 24px;
border-radius: 8px;
background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
border: 1px solid transparent!important;
background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box; 



}

.SalesAnalytics_table1___main_Firstttt{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.SalesAnalytics_table1___main_Firstttt_1{
    display: flex;
    flex-direction: column;
    gap: 8px;

}

.SalesAnalytics_table1___main_Firstttt_1_p1{
    font-family: 'Nexa-Regular' !important ;
font-size: 14px;
font-weight: 400;
line-height: 19.6px;
text-align: left;
color: #D9D9D9;
margin: 0px !important;


}
.SalesAnalytics_table1___main_Firstttt_1_p2{
    font-family: 'Nexa-Bold' !important;
font-size: 32px;
font-weight: 700;
line-height: 35.84px;
letter-spacing: -0.04em;
text-align: left;
color: #00D059;
margin: 0px !important;
}

.SalesAnalytics_table1___main_Firstttt_2{
    display: flex;
    align-items: center;
 
}











/* ....................................... */




.SalesAnalytics_table1___MAIN___11{
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) !important;
box-shadow: none !important;
margin-top: 24px !important;
scrollbar-width: none !important;
-ms-overflow-style: none !important;

}
.SalesAnalytics_table1___MAIN___11::-webkit-scrollbar{
    display: none !important;
}


.SalesAnalytics_table1___MAIN{
 
border: 0px 0px 1px 0px !important;
background: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) !important;
border-bottom: 1px solid #292929 !important;
box-shadow: 0px 0.96px 1.91px 0px #1E1E240F !important;


}


.SalesAnalytics_table1___Ptags{
    font-family: 'Nexa-Regular' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20.3px !important;
    text-align: left !important;
    color: #A6A6A6 !important;
    border-bottom: none !important;


}


.SalesAnalytics_table1___Ptags2{
    font-family: 'Nexa-Bold' !important;
font-size: 14px !important;
font-weight: 700 !important;
line-height: 20.3px !important;
text-align: left !important;
color: #FFFFFF !important;
border-bottom: none !important;
letter-spacing: 0.06em !important;

}

.expandimge____
{
    display: flex;
    justify-content: center;
}
.Organizer_Detail_Payout_Main{
    margin-top: 50px !important;
    display: flex !important;
    flex-direction: column !important;
    background: #030303;


}

.Organizer_Detail_Payout_Main_Ptag{
font-family: 'Nexa-Bold' !important;
font-size: 24px;
font-weight: 800;
line-height: 27.6px;
letter-spacing: -0.04em;
text-align: left;
color: #E6E6E6;
margin: 0px !important;

}

.Organizer_Detail_Payout_Main_Buttons__{
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: left;
    margin-top: 20px !important;
}

.Organizer_Detail_Payout_Main_Buttons__btn1{
    width: 240px;
    height: 48px;
   
    /* border: 1px solid  transparent!important; */
    border-radius: 8px !important; 
     /* background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box; */
     


    font-family: 'Nexa-Bold' !important;
font-size: 14px;
font-weight: 700;
line-height: 20.3px;
text-align: left;
/* color: #00A849; */
padding: 16px 0px 12px 12px;
        border: none;
}


.Organizer_Detail_Payout_Main_Buttons__btn2{
    width: 240px;
height: 48px;
border-radius: 8px;
background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);

/* border: 1px solid  transparent!important; */
border-radius: 8px !important;
/* background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box;  */

font-family: 'Nexa-Regular' !important;
font-size: 14px;
font-weight: 400;
line-height: 20.3px;
text-align: left;
color: #FFFFFF;
padding: 16px 0px 12px 12px;
border: none;
}

@media(max-width:760px){
.Organizer_Detail_Payout_Main_Buttons__btn1,
.Organizer_Detail_Payout_Main_Buttons__btn2{
width: 33% !important;
}

}


@media(max-width:480px){
    .Organizer_Detail_Payout_Main_Buttons__btn1,
    .Organizer_Detail_Payout_Main_Buttons__btn2{
height: 42px !important;
font-size: 10px !important;
padding: 0px !important;
text-align: center !important;
    }
    .rganizer_Detail_Payout_Main_Buttons__{
        display: flex;
        justify-content: space-between;
        gap: 5px !important;
    }
    


    }
    

.personal-info-detail-container {
    padding: 75px 78px 75px 100px;
    max-width: 100%;
  }
  
  .top-buttons {
    display: flex;
    gap: 12px;
    max-width: 100%;
   
  }
  .Simple-User-Details-heading-main{
      display: flex;
      flex-direction: column;
      gap: 24px;
  }
  .top-buttons button {
    width: 170px;
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    /* justify-content: center; */
    background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
    border: 1px solid;
    border-image-source: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0.06) 100%
    );
    color: #FFFFFF;
 
  font-family: "Nexa-Regular" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  text-align: left;
  letter-spacing: 0.02em !important;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    padding-right: 15px !important;
  }
  
  .profile-details {
      max-width: 904px;
     margin-top: 50px !important; 
    display: flex;
    flex-direction: column;
    gap: 24px !important;
    background-color: #1a1a1a;
    padding: 24px 0px 24px 24px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    margin-bottom: 20px;
  }
  .profile-details-text-heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .profile-details-text-heading p {
    font-family: "Nexa-Regular";
    font-size: 14px;
    font-weight: 400;
    color: #8f8f8f;
  
    line-height: 18.9px;
    text-align: left;
    margin: 0px !important;
  }
  .profile-details-text-heading h2{
      font-family:"Nexa-Bold";
  font-size: 16px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  color: #FFFFFF;
  margin: 0px !important;
  }
  .profile-picture {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .profile-picture img {
    width: 100px;
    border-radius: 50px;
  }
  .profile-picture p{
      font-family: "Nexa-Regular";
      font-size: 14px;
      font-weight: 400;
      color: #8f8f8f;
      line-height: 18.9px;
      text-align: left;
      margin: 0px !important;
    }
  .export-button {
    display: flex;
    margin-top: 50px !important;
  }
  
  .export-button button {
      width: 165px;
      height: 44px;
      border-radius: 100px ;
      display: flex;
      justify-content: center;
      align-items: center;
    background: #00D059;
    border: none;
    font-family:"Nexa-Bold";
    font-size: 14px;
    font-weight: 800;
    line-height: 19.6px;
    text-align: left;
    
  }
  .export-button button:hover {
    background: rgba(0, 208, 89, 0.8);
  }
  
  .Simple-User-Details-heading{
  font-family: "Nexa-Bold";
  font-size: 36px;
  font-weight: 800;
  line-height: 41.4px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #E6E6E6;
  margin: 0px !important;
  }
  /* .link-button-between{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid;
      width: 112px;
      height: 34px;
      border-radius: 100px ;
     
  font-family:"Nexa-Regular";
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  text-align: left;
  margin: 0px !important;
  border: 1px solid transparent !important;

border-image-source: linear-gradient(135deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) !important;
border-image-slice: 1 !important; */

  /* color: #00D059;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box; */
  /* border: 1px solid #13FF7A; */
  
  /* } */
  
  @media (max-width: 1400px) {}
  /* General adjustments for tablets and small laptops */
  @media (max-width: 1024px) {
      .personal-info-detail-container {
        padding: 50px 40px;
      }
    
    
    
    
      .Simple-User-Details-heading {
        font-size: 28px;
      }
    
      .profile-picture img {
        width: 80px;
        border-radius: 50px;
      }
    
      /* .export-button button {
        width: 140px;
        height: 40px;
      } */
    }
    .personal-info-detail-container 
    {
      padding: 75px 50px 75px 50px;
    }
 @media(max-width:1300px)
 {
  .top-buttons
  {
    flex-wrap: wrap;
  }
 }
    /* Mobile adjustments */
    @media (max-width: 768px) {
      .personal-info-detail-container {
        padding: 30px 20px;
      }
    
      .Simple-User-Details-heading {
        font-size: 24px;
      }
    
      .profile-details {
        padding: 16px;
      }
    
      .profile-details-text-heading h2 {
        font-size: 14px;
      }
    
     
  /*   
      .export-button button {
        width: 130px;
        height: 36px;
        font-size: 12px;
      } */
    }
    
    /* Small screens below 430px */
    @media (max-width: 430px) {
      .personal-info-detail-container {
        padding: 30px 25px;
      }
      .Organizer_detail_Follower__Btn{
        max-width: 430px !important;
      }
      .Simple-User-Details-heading {
        font-size: 20px;
      }
      .Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image h2{
        font-size: 24px !important;
      }
      .top-buttons button {
          max-width:420px!important;
          justify-content: center !important;
      }
    
      .profile-details-text-heading h2 {
        font-size: 12px;
      }
    
      .profile-details {
        padding: 12px;
      }
    
      .export-button button {
        max-width: 410px !important;
        height: 34px;
        font-size: 11px;
      }
    
      .profile-picture img {
        width: 70px;
        border-radius: 50px;
      }
    }
    
    .personal-info-detail-container____ekele{
      margin-top: 50px !important;
    }
    .personal-info-detail-container 
    {
      padding: 75px 78px 75px 100px;
    }
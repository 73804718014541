/* .festivals__multitaks_container_users
{
    padding: 75px 100px !important;
} */
 .main-even-name-text-style-for_sales-padding-margin-name__sales2
 {
    font-family:"Nexa-Regular" !important ;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20.3px !important;
text-align: left !important;
color: #ffffff !important;
 }
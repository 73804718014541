.paidRequests{
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-right: 24px !important;
    gap: 40px;
}
.Maindiv1{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.Amount{
display: flex;
align-items: center;
gap: 16px;
}
.amountTypo{
    font-family: 'Nexa-Bold' !important;
    font-weight: 800;
    font-size:24px ;
    line-height: 27.6px;
    color: rgba(230, 230, 230, 1);
}
.firstTable{
background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    border-radius: 8px;
    gap: 24px;
}
.FundRaied{
    font-family: 'Nexa-Regular' !important;
    font-weight: 400;
    font-size:14px ;
    line-height: 19.6px;
    color: rgba(217, 217, 217, 1);
}
.Value{
    font-family: 'Nexa-Bold' !important;
    font-weight: 700;
    font-size:32px ;
    line-height: 35.84px;
    color: rgba(0, 208, 89, 1);
}
.SalesAnalytics_table1___main{
    display: flex;
    flex-direction: column;
    max-width: 904px;
padding: 24px;
border-radius: 8px;
background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
border: 1px solid transparent!important;
background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box;
}
.SalesAnalytics_table1___main_Firstttt{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.SalesAnalytics_table1___main_Firstttt_1{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.SalesAnalytics_table1___main_Firstttt_1_p1{
    font-family: 'Nexa-Regular' !important ;
font-size: 14px;
font-weight: 400;
line-height: 19.6px;
text-align: left;
color: #D9D9D9;
margin: 0px !important;
}
.SalesAnalytics_table1___main_Firstttt_1_p2{
    font-family: 'Nexa-bold' !important;
font-size: 32px;
font-weight: 700;
line-height: 35.84px;
letter-spacing: -0.04em;
text-align: left;
color: #00D059;
margin: 0px !important;
}
.SalesAnalytics_table1___main_Firstttt_2{
    display: flex;
    align-items: center;
}
/* ....................................... */
.SalesAnalytics_table1___MAIN___11{
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) !important;
box-shadow: none !important;
margin-top: 24px !important;
scrollbar-width: none !important;
-ms-overflow-style: none !important;
}
.SalesAnalytics_table1___MAIN___11::-webkit-scrollbar{
    display: none !important;
}
.SalesAnalytics_table1___MAIN{
border: 0px 0px 1px 0px !important;
background: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) !important;
border-bottom: 1px solid #292929 !important;
box-shadow: 0px 0.96px 1.91px 0px #1E1E240F !important;
}
.SalesAnalytics_table1___Ptags{
    font-family: 'Nexa-Regular' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20.3px !important;
    text-align: left !important;
    color: #A6A6A6 !important;
    border-bottom: none !important;
}
/* .SalesAnalytics_table1___Ptags2{
    font-family: Nexa!important;
font-size: 14px !important;
font-weight: 700 !important;
line-height: 20.3px !important;
text-align: left !important;
color: #FFFFFF !important;
border-bottom: none !important;
} */
 .withdrawldetails{
    color: rgba(230, 230, 230, 1);
    font-family: 'Nexa-bold';
    font-weight: 800;
    font-size: 24px;
    line-height: 27.6px;
 }
 .maindiv2{
    display: flex;
    flex-direction: column;
    gap: 20px;
 }
 .Table2{
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
    padding: 16px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
 }
 .bankName{
    color: rgba(230, 230, 230, 1);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Nexa-regular';
    line-height: 20px;
 }
 .bank{
    color: rgba(230, 230, 230, 1);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Nexa-bold';
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
 }
 .row1{
    display: flex;
    justify-content: space-between;
 }
 .buttons{
    padding-top: 10px;
    display: flex;
    gap: 15px;
    padding-bottom: 50px;
 }
 .button1{
    display: flex;
    background-color: rgba(0, 208, 89, 1);
    border-radius: 100px;
    padding-top: 13px;
    padding-bottom: 11px;
    width: 165px;
    justify-content: center;
    align-items: center;
    font-family: 'Nexa-bold';
    font-size: 14px;
    font-weight: 800;
    line-height: 19.6px;
    border: none;
    color: rgba(3, 3, 3, 1);
 }
 .button2 {
    display: flex;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    border-radius: 100px;
    padding-top: 13px;
    padding-bottom: 11px;
    width: 165px;
    justify-content: center;
    align-items: center;
    font-family: 'Nexa-bold';
    font-size: 14px;
    font-weight: 800;
    line-height: 19.6px;
    border: none;
    color: rgba(0, 208, 89, 1);
    position: relative;
    z-index: 1;
    overflow: hidden; /* Ensures the pseudo-element remains within the button */
}
.button2::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: inherit;
    padding: 2px;
    background: linear-gradient(135deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: -1;
}
/* Target screens 750px or smaller */
@media (max-width: 750px) {
    .SalesAnalytics_table1___Ptags2 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
    }
    .buttons{
        display: flex;
        flex-direction: column;
    }
    .button2 {
        width: 100% !important;
    }
    .button1{
        width: 100% !important;
    }
    .bank {
        max-width: 60%; /* Adjust this value as necessary */
    }
  }
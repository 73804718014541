.paidRequests{
    padding-top: 40px;
}
.Amount{
display: flex;
align-items: center;
gap: 16px;
}
.amountTypo{
    font-family: 'Nexa' !important;
    font-weight: 800;
    font-size:24px ;
    line-height: 27.6px;
    color: rgba(230, 230, 230, 1);
}
.firstTable{

background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
}
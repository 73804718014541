/* .reson-text{
  
    font-family: Nexa-Bold;
    font-size: 24px;
    font-weight: 800;
    line-height: 27.6px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #FFFFFF;
    
    
    
    }
    .main-container{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    .reson-container-svg{
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 24px;
        border-bottom: 1px solid #292929;
        background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
    
    }
    .text-c0ntainer-report{
        border-radius: 12px;
        background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
        border: 1px solid;
    
        border-image-source: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
        
        padding: 20px 31px 20px 12px;
        border-bottom: 1px solid #292929
    }
    .reson-report-text-sorem{
        font-family: Nexa;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #8F8F8F;
    
    
    }
    .text_new_container{
        padding: 24px;
    }
    .reson-report-overall-container{
        border-radius: 12px;
        max-width: 650px;
        
        background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
        border: 1px solid;
    
    border-image-source: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
    }
    
        
    
     */
.modal__feedback__container {
  display: flex !important;
  justify-content: center !important;
}
.modal-header {
  justify-content: space-between !important;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  border-bottom: 1px solid #292929 !important;
  color: #ffffff !important;
  padding: 20px 24px !important;
  border-radius: 12px 12px 0px 0px !important;
}
.modal-content {
  width: 650px !important;
  border: none !important;
}
.text-popup {
  font-family: Nexa-Bold;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.2px;
  text-align: left;
  text-transform: capitalize;

  color: #8f8f8f;
}
.textpopup-container {
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);

  padding: 20px 31px 20px 12px !important;
  border: 1px solid;

  border-radius: 12px;
}
.modal-content {
  border: none !important;
  border-bottom: 1px solid #292929 !important;
}
.main-text-container {
  padding: 24px;
  margin-bottom: 13px !important;
  border-bottom: 1px solid #292929;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
}
.main-popup-container {
  border-radius: 12px;
}
.modal-content {
  background-color: transparent !important;
  outline: none;
  border: none !important;
  border: 1px solid;

  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);

  border-radius: 12px !important;
  border: 1px solid !important;

  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
}
.modal-dialog {
  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  ) !important;
  border-radius: 12px !important;
}
.feedback__title__heading {
  font-family: Nexa-Bold;
  font-size: 24px;
  font-weight: 800;
  line-height: 27.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
}
@media (max-width: 650px) {
  .modal-content {
    width: 100% !important;
  }
}

.given-to-the-width-to-all {
  max-width: 1115px;
  margin-bottom: 100px !important;
  padding-right: 0px !important;
  padding-left: 32px !important;
}
.given-to-the-width-to-all__1
{
  margin-bottom: 100px !important;
  padding-right: 64px !important;
  padding-left: 32px !important;
}
.personal-info-detail-container-----kyc
{
  max-width: 1260px;
  margin-top: 40px !important;
}
.main-even-name-text-style-for-padding-margin-name {
  text-transform: none !important;
}
.export__button__container_kyc {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px !important;
  padding-right: 60px;
}
.export-button-kyc {
  width: 165px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00d059;
  border: none;
  font-family: "Nexa-Bold";
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  text-align: left;
  color: #030303 !important;
}
.kyc__approval__main__div {
  padding-bottom: 160px;
}
.filter__button__top_container
{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 60px !important;
    margin-top: 40px !important;
}

.filter__button__main_container {
  display: flex;
  justify-content:space-between;
  padding: 19px 0px 19px 16px;
  border-radius: 8px;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  border: 1px solid;

  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );

  box-shadow: 0px 3px 5px 0px #232323 inset;
  width: 184px;
  height: 54px;
  align-items: center;
}
.filter__text
{
font-family: Nexa;
font-size: 14px;
font-weight: 400;
line-height: 19.6px;
text-align: left;
color: #bfbfbf;

}
.dropdown-toggle::after 
{
    display: none !important;
}
.dropdown___menu__container
{   border-radius: 8px;
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
    width: 184px;
    padding: 16px !important;
    margin-top: 50px !important;

}
.dropdown__filter__text
{
font-family: Nexa;
font-size: 14px;
font-weight: 400;
line-height: 19.6px;
text-align: left;
color: #FFFFFF !important;

}
.approved__button__main__container
{
  padding-left: 32px;
  padding-top: 40px;
}

@media (max-width: 475px) {
  .given-to-the-width-to-all {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .given-to-the-width-to-all__1
  {
padding-left: 20px !important;
padding-right: 20px !important;
  }
  .approved__button__main__container
  {
   padding-left: 0px;
  }
}

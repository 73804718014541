.launch_party_text {
  font-family: "Nexa-Bold";
  font-size: 32px;
  font-weight: 800;
  line-height: 35.84px;
  letter-spacing: -0.02em;
  text-align: left;
  color: white;
}
.launch_sales-wrape {
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  border-radius: 8px;
  padding: 16px 0px 16px 12px;
  border: 1px solid;
  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  width: 100%;
}
.launch_sales_revenue-wraper {
  margin-top: 32px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.launch_sales_text {
  font-family: "Nexa-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  text-align: left;
  color: #ffffff;
}
.launch_sales_number {
  font-family: "Nexa-Bold";
  font-size: 30px;
  font-weight: 800;
  line-height: 43.5px;
  text-align: left;
  color: #00d059;
}
.launch__main {
  padding: 75px 97px 130px 100px;
}
.launch_party_inerdiv {
  margin-top: 50px !important;
}
.Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image {
  margin-bottom: 50px !important;
}
.main-even-name-text-style-for_sales-padding-margin-name__sales__launch {
  font-family: Nexa-Bold !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #ffffff !important;
  white-space: nowrap !important;
  text-decoration: none !important;
}
.ticket__types__heading {
  font-family: Nexa-Bold;
  font-size: 32px;
  font-weight: 800;
  line-height: 35.84px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
  margin-top: 40px !important;
}
@media (max-width: 600px) {
  .launch_sales_revenue-wraper {
    margin-top: 20px !important;
    flex-direction: column;
    gap: 12px;
  }
}
@media (max-width: 500px) {
  .launch_sales_revenue-wraper {
    margin-top: 20px !important;
    flex-direction: column;
    gap: 12px;
  }
  .Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image {
    margin-bottom: 25px !important;
  }
  .launch__main {
    padding: 40px 60px 80px 60px;
  }
  .launch_party_inerdiv {
    margin-top: 30px !important;
  }
  .export-button {
    margin-top: 0px !important;
    justify-content: center;
  }
}
@media (max-width: 425px) {
  .Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image h2 {
    font-size: 24 !important;
  }
  .launch_party_inerdiv {
    margin-top: 25px !important;
  }
  .launch__main {
    padding: 30px 25px 50px 25px;
  }
  .launch_party_text {
    font-size: 24px;
    line-height: 25px;
    text-align: center;
  }
  .launch_sales_number {
    font-size: 25px;
    line-height: 28.5px;
  }
}
.even-name-text-style-for_sales {
  font-family: "Nexa-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #a6a6a6 !important;
}

.User__detail_Scanner___Btn{
    margin-top: 0px !important;
    width: 165px !important;
height: 44px !important;
border-radius: 100px !important;
background: #00D059 !important;
font-family: 'Nexa-Bold' !important;
font-size: 14px !important;
font-weight: 800 !important;
line-height: 19.6px !important;
text-align: center !important;
color: #030303 !important;
border: none !important;

}

.User__detail_Scanner___Btn:hover
{
    background: rgba(0, 208, 89, 0.8) !important;
}

.User__detail_Scanner_____MAin{
    display: flex;
    flex-direction: column;
    gap: 50px !important;
    max-width: 1440px;
padding: 75px 78px 75px 100px;
background: #030303;

}

.User__detail_Scanner___Div1{
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

}

.User__detail_Scanner___Div1__ptag{
    font-family: 'Nexa-Bold' !important;
font-size: 36px;
font-weight: 800;
line-height: 41.4px;
letter-spacing: -0.04em;
text-align: left;
color: #E6E6E6;
margin: opx !important;
}
@media (max-width: 800px) {
  
    .just-for-to-query-use{
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}
.reson-text{
  
font-family: Nexa-Bold;
font-size: 24px;
font-weight: 800;
line-height: 27.6px;
letter-spacing: -0.02em;
text-align: left;
color: #FFFFFF;



}
.main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.reson-container-svg{
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    border-bottom: 1px solid #292929;
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);

}
.text-c0ntainer-report{
    border-radius: 12px;
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
    border: 1px solid;

    border-image-source: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    
    padding: 20px 31px 20px 12px;
    border-bottom: 1px solid #292929
}
.reson-report-text-sorem{
    text-transform: capitalize;
    font-family: Nexa;
font-size: 12px;
font-weight: 700;
line-height: 16.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #8F8F8F;


}
.text_new_container{
    padding: 24px;
}
.reson-report-overall-container{
    border-radius: 12px;
    max-width: 650px;
    
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
    border: 1px solid;

border-image-source: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);

display: flex;
flex-direction: column;
justify-content: center;


}

    


.CollectiblesOwned-to-give-width-attendeed{
    max-width: 1262px !important;
    /* padding-left: 100px !important; */
}
.personal-info-detail-container____________chnge{
    margin-top: 50px !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
}
.explore___button1
{
    /* margin-left: 100px !important; */
}
@media(max-width:1000px)
{
    .personal-info-detail-container____________chnge{
     
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}
@media(max-width:490px)
{
    .personal-info-detail-container____________chnge{
     
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}
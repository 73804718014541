.push__notifications__main_div {
  background: url("/public/Images/Signed_in_main/Auth.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
  padding: 40px 32px;
  gap: 40px;


}
.push__notifications____div {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.notifcation__title__container {
  padding: 16px 12px;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  border: 1px solid;
  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  border-radius: 8px;
  width: 600px;
}
.notification__title__heading_container {
  display: flex;
  flex-direction: column;
}
.notification__title_heading {
  font-family: Nexa-Bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}
.title__input__field {
  background-color: transparent;
  border: none;
  color: #8f8f8f;
  font-family: Nexa-Bold;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.2px;
  text-align: left;
}
.title__input__field::placeholder {
  font-family: Nexa-Bold;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.2px;
  text-align: left;
  color: #8f8f8f;
}
.title__input__field:focus {
  outline: none;
  font-family: Nexa-Bold;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: #8f8f8f;
}
.notifcation__detail__container {
  padding: 16px 12px;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  border: 1px solid;
  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  border-radius: 8px;
  width: 600px;
  height: 160px;
}
.notification__details_heading {
  font-family: Nexa-Bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}
.notification__title__heading_container_1
{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.details__input__field {
  background-color: transparent;
  border: none;
  font-family: Nexa-Bold;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.2px;
  text-align: left;
  color: #8f8f8f;
  height: 100%;
  resize: none;
}
.details__input__field::placeholder {
  font-family: Nexa-Bold;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.2px;
  text-align: left;
  color: #8f8f8f;
}
.details__input__field:focus {
  outline: none;
  color: #8f8f8f;
}
.upload__image__container {
  padding: 16px 12px;
  width: 600px;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  border: 1px solid;
  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  border-radius: 8px;
}
.upload__image__container_inner {
  display: flex;
  justify-content: space-between;
}
.image__text__main {
  font-family: Nexa-Bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}
.optional__text {
  font-family: Nexa;
  font-size: 11px;
  font-weight: 400;
  line-height: 12.5px;
  text-align: left;
  color: #ffffff;
}
.upload__image__container_inner1 {
  display: flex;
  flex-direction: column;
}
.upload__image__text {
  font-family: Nexa;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.2px;
  text-align: left;
  color: #8f8f8f;
}
.send__button__main {
  width: 165px;
  height: 44px;
  border-radius: 100px;
  background: #00d059;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-family: Nexa-Bold;
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  text-align: left;
  color: #030303;
}

@media (max-width: 1000px) {
  .notifcation__title__container {
    width: 100%;
  }
  .notifcation__detail__container {
    width: 100%;
  }
  .upload__image__container {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .push__notifications__main_div {
    padding: 40px 10px;
    background: none;
  }
}

.CollectiblesOwned-to-give-width-attendeed{
    max-width: 1015px !important;
    
}
.main-even-name-text-style-for-padding-margin-name_new{
    font-family: Nexa !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20.3px !important;
text-align: left !important;
color: #8F8F8F !important;


}
.personal-info-detail-container-new{
    max-width: 1240px;
    padding: 100px 75px 100px 75px !important;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.main-even-name-text-style-for-padding-margin-type-newcolor{
    font-family:Nexa-Bold !important;
font-size: 14px !important;
font-weight: 700 !important;
line-height: 20.3px !important;
text-align: left !important;

color: #00D059 !important;

}
@media (max-width:425px) {
    .personal-info-detail-container-new{
        padding: 40px 20px 100px 20px !important;
    }

    
}
/* .css-13aaiuh-MuiDrawer-docked .MuiDrawer-paper{
    background-color: black !important;
} */
.Logo{
    padding: 32px 56.25px 32px 56.25px;

}
.drawer{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
}
.List{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    gap: 30px;
}
.Logout{
    width: 205px;
    height: 52px !important;
    display: flex;
    bottom: 47.6px; 
    gap: 14px;
    align-items: center;
    justify-content: center;
    border: 1px solid #FF1717;
    border-radius: 110px !important;
    cursor: pointer;

}
.Logout:hover {
    background-color: rgba(255, 23, 23, 0.1);
   
  }
.logoutPara{
    font-family: 'Nexa-bold' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    text-align: left;
    color: #FFFFFF;
    margin: 0px !important;

}
.sidebarButtons {
    color: #333;
    background-color: transparent;
    position: relative; /* Needed to position the ::before pseudo-element */
}

.selectedButton {
    position: relative;
    width: 216px !important;
    height: 48px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Nexa-Bold";
    font-size: 14px;
    font-weight: 800;
    line-height: 19.6px;
    text-align: left;
    color: #00d059;
    background: transparent !important;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid transparent !important
  }
  .selectedButton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px; 
    padding: 1px; 
    background: linear-gradient(
      135deg,
      #13ff7a 0.2%,
      #002b12 50.2%,
      #13ff7a 100.2%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
@media(max-height:800px)
{
 .Logout
 {
    position: unset !important;
 }
}
.Organizer_detail_Follower_Main {
  margin-top: 50px !important;
  background-color: #030303 !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none;
  box-shadow: none !important;
}
.Organizer_detail_Follower_Main::-webkit-scrollbar {
  display: none !important;
}
.Organizer_detail_Follower_Main___Row {
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 100%,
    rgba(255, 255, 255, 0.06) 100%
  ) !important;
  font-family: "Nexa-Bold" !important;
  font-size: 14px !important;
  font-weight: 800 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #8f8f8f !important;
  border-bottom: 1px solid #292929 !important;
  margin: 0px !important;
}

.Organizer_detail_Follower_Main___Body {
  font-family: "Nexa-Bold" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #ffffff !important;
  margin: 0px !important;
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  ) !important;
  border-bottom: 1px solid #292929 !important;
  letter-spacing: 0.04em !important;
}
.Organizer_detail_Follower_Main___Body1 {
  font-family: "Nexa-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #8f8f8f !important;
  margin: 0px !important;

  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  ) !important;
  border-bottom: 1px solid #292929 !important;
}

.rganizer_detail_Follower_Main___Body_Top {
  background-color: transparent !important;
  border-color: #292929 !important ;
}

.Organizer_detail_Follower__Btn {
  margin-top: 0px !important;
  width: 165px;
  height: 44px;
  border-radius: 100px !important;
  background: #00d059 !important;
  font-family: "Nexa-Bold" !important;
  font-size: 14px !important;
  font-weight: 800 !important;
  line-height: 19.6px !important;
  text-align: center !important;
  color: #030303 !important;
  border: none !important;
  margin-bottom: 0px !important;
}

.Organizer_Detail_Payout_Main_Table---- {
  margin-top: 50px !important;
}
.empty__state__main__cintainer__2
{
  text-align: center;
    color: #fff;
    background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
    border-bottom: none;
    border-radius: 8px;
    width: 100%;
    height: 257px;
    border-bottom: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px !important;
}
.Organizer_detail_Follower__Btn:hover {
  background: rgba(0, 208, 89, 0.8) !important;
}
@media (max-width: 430px) {
  .Organizer_detail_Follower__Btn {
    max-width: 430px !important;
  }
}

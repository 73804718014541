.support-info-detail-container-new
{
    padding: 75px 78px 75px 100px !important;
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 1262px;
}
.CollectiblesOwned-to-give-width-attendeed{
    max-width: 1262px !important;
    
}
.support__detail__Subject_container {
  padding: 24px;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  border-radius: 8px;
  border: 1px solid;

  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );

  box-shadow: 0px 3px 5px 0px #232323 inset;
  display: flex;
  flex-direction: column;
  gap:24px;

}
.support__Detail__subject_container-1
{
display: flex;
flex-direction: column;
gap:10px
}
.subject__text__1
{
    font-family: Nexa;
font-size: 14px;
font-weight: 400;
line-height: 18.9px;
text-align: left;
color: #8f8f8f;

}
.subject__text__description
{
    font-family: Nexa-Bold;
font-size: 16px;
font-weight: 700;
line-height: 21.6px;
text-align: left;
color: #FFFFFF

}
.subject__text__description_detail
{
    font-family: Nexa-Bold;
    font-size: 16px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
    color: #FFFFFF;
    
}
.attachments__container
{
    display: flex;
    gap:6px;
    flex-wrap: wrap;

}
.attachment__image
{
    height: 120px;
    width: 120px;
    border-radius: 8px;
}
@media(max-width:500px)
{
    .support-info-detail-container-new
{
    padding: 75px 20px 350px 20px !important;
}
}
.personal-info-detail-container {
  padding: 75px 78px 75px 100px;
  max-width: 100%;
}
.personal-info-detail-container__E_e{
margin-top: 50px !important;
padding-left: 100px !important;
padding-right: 100px !important;
padding-bottom: 100px !important;
}
.top-buttons {
  display: flex;
  gap: 12px;
  max-width: 100%;
  margin-bottom: 50px !important;

}
.Simple-User-Details-heading-main {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.top-buttons button {
  max-width: 170px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  /* justify-content: center; */
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%);
  border: 1px solid;
  border-image-source: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  color: #ffffff;

  font-family: "Nexa-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  text-align: left;

  border-radius: 8px;
  cursor: pointer;
  width: 100%;
}
.no__image-placeholder
{
font-family: Nexa-Regular;
font-size: 16px;
font-weight: 500;
line-height: 21.6px;
text-align: left;
color: #FFFFFF

}
.profile-details {
  max-width: 1260px !important;
  margin-top: 50px !important;
  display: flex;
  flex-direction: column;
  gap: 24px !important;
  background-color: #1a1a1a;
  padding: 24px 0px 24px 24px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  margin-bottom: 20px;
}
.profile-details-text-heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.profile-details-text-heading p {
  font-family: "Nexa-Regular";
  font-size: 14px;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 18.9px;
  text-align: left;
  margin: 0px !important;
}
.profile-details-text-heading h2 {
  font-family: "Nexa-Bold";
  font-size: 16px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  color: #ffffff;
  margin: 0px !important;
}
.profile-picture {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.profile-picture img {
  width: 100px;
  border-radius: 50px;
}
.profile-picture p {
  font-family: "Nexa-Regular";
  font-size: 14px;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 18.9px;
  text-align: left;
  margin: 0px !important;
}
.export-button {
  display: flex;
  margin-top: 50px !important;
}
.after-qa-try-for-space{
  font-family: "Nexa-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #8f8f8f !important;
  line-height: 18.9px !important;
  text-align: left !important;
  margin: 0px !important;
}
.export-button button {
  width: 165px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00d059;
  border: none;
  font-family: "Nexa-Bold";
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  text-align: left;
}
.export-button button:hover {
  background: rgba(0, 208, 89, 0.8);
}
.Simple-User-Details-heading {
  font-family: "Nexa-Bold";
  font-size: 36px;
  font-weight: 800;
  line-height: 41.4px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #e6e6e6;
  margin: 0px !important;
}
/* .link-button-between {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 34px;
  border-radius: 100px;
  font-family: "Nexa-Regular";
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  text-align: left;
  margin: 0px !important;

  background: linear-gradient(#2e2e2f, #2e2e2f) padding-box,
    linear-gradient(167deg, #13ff7a 0.2%, #002b12 50.2%, #13ff7a 100.2%)
      border-box;
  border: 1px solid #13ff7a;
  border: 1px solid transparent;
  color: hsl(146, 100%, 41%);
} */

.link-button-between {
  position: relative;
  width: 112px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nexa-Bold";
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  text-align: left;
  color: #00d059;
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  border-radius: 100px;
  overflow: hidden;
}
.link-button-between::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100px; /* Match button's border-radius */
  padding: 1px; /* Border thickness */
  background: linear-gradient(
    135deg,
    #13ff7a 0.2%,
    #002b12 50.2%,
    #13ff7a 100.2%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
.link-button-between {
  position: relative;
  z-index: 1; /* Ensures button content is above pseudo-element */
  border: none;
  background: transparent; /* Button's background */
  color: #00d059;
  font-family: "Nexa-Bold";
  font-size: 14px;
  font-weight: 800;
}

@media (max-width: 1400px) {
}
/* General adjustments for tablets and small laptops */
@media (max-width: 1024px) {
  .personal-info-detail-container {
    padding: 50px 40px;
  }

  .Simple-User-Details-heading {
    font-size: 28px;
  }

  .profile-picture img {
    width: 80px;
    border-radius: 50px;
  }
  .top-buttons
  {
    flex-wrap: wrap;
  }

  /* .export-button button {
      width: 140px;
      height: 40px;
    } */
}
@media(max-width:1000px)
{
.personal-info-detail-container__E_e

{
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.top-buttons
{
  display: flex !important;
  justify-content: center !important;
}
}
/* Mobile adjustments */
@media (max-width: 768px) {
  .personal-info-detail-container {
    padding: 30px 20px;
  }

  .Simple-User-Details-heading {
    font-size: 24px;
  }

  .profile-details {
    padding: 16px;
  }

  .profile-details-text-heading h2 {
    font-size: 14px;
  }

  /*   
    .export-button button {
      width: 130px;
      height: 36px;
      font-size: 12px;
    } */
}

/* Small screens below 430px */
@media (max-width: 430px) {
  .personal-info-detail-container {
    padding: 30px 25px;
  }

  .Simple-User-Details-heading {
    font-size: 20px;
  }
  .Organizer_detail_Follower__Btn{
    max-width: 430px !important;
  }
  .top-buttons button {
    max-width: 420px !important;
    justify-content: center !important;
  }

  .profile-details-text-heading h2 {
    font-size: 12px;
  }

  .profile-details {
    padding: 12px;
  }

  .export-button button {
    max-width: 410px !important;
    height: 34px;
    font-size: 11px;
  }

  .profile-picture img {
    width: 70px;
    border-radius: 50px;
  }
  .personal-info-detail-container__E_e

{
  padding-left: 20px !important;
  padding-right: 20px !important;
}
}

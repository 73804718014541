.sales__rvsp__ticketing__main__container {
  padding: 75px 100px;
  display: flex;
  flex-direction: column;
  gap:40px
}
.sales__rvsp__ticketing__main__container__1 {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.ticket__type__detail__main__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  background: linear-gradient(360deg, rgb(15, 15, 15) 72%, rgb(26, 26, 26) 100%)
      padding-box,
    linear-gradient(
        167deg,
        rgb(19, 255, 122) 0.2%,
        rgb(0, 43, 18) 50.2%,
        rgb(19, 255, 122) 100.2%
      )
      border-box;

  color: rgb(0, 168, 73);

  border: 1px solid transparent;
  border-image-source: linear-gradient(
    135deg,
    #13ff7a 0.2%,
    #002b12 50.2%,
    #13ff7a 100.2%
  );

  border-radius: 16px;

  max-width: 610px;
}

.ticket__type__detail__main__container__1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.ticket__type__detail__main__container__2
{
  display: flex;
  justify-content: space-between;
}
.ticket__type__detail__main__container2 {
  display: flex;
  justify-content: space-between;
}
.ticket__name__Sales__1 {
  font-family: Nexa;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  color: #8f8f8f;
  white-space: nowrap;
}
.ticket__detail__Sales__1 {
  font-family: Nexa-Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  color: #d9d9d9;
}
.even-name-text-style-for_sales {
  font-family: Nexa !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #a6a6a6 !important;
}
.even-name-text-style-for_sales1 {
  font-family: Nexa !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #ffffff !important;
}
.main-even-name-text-style-for_sales-padding-margin-name__sales {
  font-family: Nexa !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #8f8f8f !important;
}
.main-even-name-text-style-for_sales-padding-margin-name__sales1
{
  font-family: Nexa !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #ffffff !important;
}
.for-to-give-the-padding1
{
  font-family: Nexa !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20.3px !important;
  text-align: left !important;
  color: #ffffff !important;
}

.ticket__type__detail__main__container_two {
  margin-top: 56px !important;
}
@media (max-width: 650px) {
  .sales__rvsp__ticketing__main__container {
    padding: 50px 50px;
  }
}
@media(max-width:570px)
{
  .ticket__type__detail__main__container__2
  {
    flex-direction: column;
  }
}
@media (max-width: 450px) {
  .sales__rvsp__ticketing__main__container {
    padding: 50px 20px;
  }
}
@media (max-width: 400px) {
  .ticket__name__Sales__1 {
    font-size: 12px;
  }
  .ticket__detail__Sales__1 {
    font-size: 12px;
  }
}

.event__main__container
{
  background: url("/public/Images/Signed_in_main/Auth.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
  padding: 40px 32px;
  gap: 40px;
}
.events-container {
  margin-top: 50px !important;
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 904px;
}

.event-card {
  height: 288px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 11px;
  padding: 0 24px 24px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top;
  border: 1px solid #424242

}

.event-title {
  font-family: 'Nexa-Bold' !important;
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #FFFFFF;
  margin: 0 !important;
}

.export-btn {
  font-family: 'Nexa-Bold' !important;
  font-size: 16px;
  padding: 10px 20px;
  background: #654EA3;
  color: #FFFFFF;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

@media (max-width: 1170px) {
  .events-container {
    max-width: unset;
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 645px) {
  .events-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .event-card {
   width: 100%;
  }
  .event-title {
    font-size: 22px;
  }
}

@media (max-width: 515px) {
  .events-container {
    display: flex;
    flex-wrap: wrap;
  }
}

.Organizer_detail_Follower_Main_____for_bg__1{
    background: transparent !important;
  margin-top: 50px !important;
  padding-right: 64px !important;
 padding-left: 32px !important;
 -ms-overflow-style: none !important;
 scrollbar-width: none !important;
 /* padding-bottom: 160px !important; */

}
.kyc__main__table
{
  margin-bottom: 100px !important;
  padding-right: 32px !important;
  /* padding-bottom: 160px !important; */
}
.RequestS_Details_Representative_divlast {
  margin-top: 50px !important;
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}

.RequestS_Details_Representative_divlastBtn1 {
  width: 165px !important;
  height: 44px !important;
  border-radius: 100px !important;
  background: #00d059 !important;
  font-family: Nexa-Bold !important;
  font-size: 14px !important;
  font-weight: 800 !important;
  line-height: 19.6px !important;
  text-align: center !important;
  color: #030303 !important;
  border: none !important;
}
.RequestS_Details_Representative_divlastBtn1:hover {
  background: rgba(0, 208, 89, 0.8) !important;
}
.RequestS_Details_Representative_divlastBtn2 {
  width: 165px;
  height: 44px;
  font-family: Nexa-Bold;
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  text-align: center;
  color: #00d059;
  border: 1px solid transparent !important;
  border-radius: 100px;
  background: linear-gradient(360deg, #0f0f0f 72%, #1a1a1a 100%) padding-box,
    linear-gradient(167deg, #13ff7a 0.2%, #002b12 50.2%, #13ff7a 100.2%)
      border-box;
}
.RequestS_Details_Representative_divlastBtn2:hover {
  background: linear-gradient(
        360deg,
        rgba(15, 15, 15, 0.8) 72%,
        rgba(26, 26, 26, 0.8) 100%
      )
      padding-box,
    linear-gradient(
        167deg,
        rgba(19, 255, 122, 0.8) 0.2%,
        rgba(0, 43, 18, 0.8) 50.2%,
        rgba(19, 255, 122, 0.8) 100.2%
      )
      border-box;
}

.private___ticketing__main__container__1
{
  padding: 75px 100px;
  display: flex;
  flex-direction: column;
  gap:50px
}
.private___ticketing__main__container__2
{
  display: flex;
  flex-direction: column;
  gap:24px
}
.box_uper_text
{
    font-family: "Nexa-Regular";
font-size: 14px;
font-weight: 400;
line-height: 19.6px;
text-align: left;
color: #D9D9D9;
margin-bottom: 8px !important;
}
.box_uper_text2
{
    font-family: 'Nexa-Bold';
font-size: 32px;
font-weight: 700;
line-height: 35.84px;
letter-spacing: -0.04em;
text-align: left;
color: #00D059;
}
.box_uper_text-wraper
{
    width: 100%;
}
.table__uper__content__wraper
{
    margin-top: 24px !important;
    padding: 24px !important;
    background: linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%);
    border: 1px solid;
    border-image-source: linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    box-shadow: 0px 3px 5px 0px #232323 inset;

    
}
.table__uper__content__wraper_1
{
  display: flex;
}
.main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing
{
  font-family: Nexa !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20.3px !important;
text-align: left !important;
color: #FFFFFF !important;

}
.attendance__events__main__container
{
  display: flex;
  flex-direction: column;
  gap:50px
}
.wallet-container
{
  display: flex;
  flex-direction: column;
  gap:50px
}

@media(max-width:650px)
{
  .private___ticketing__main__container__1
  {
    padding: 50px 50px;
  }
  .table__uper__content__wraper_1
  {
    flex-direction: column;
  }
}
@media(max-width:450px)
{
  .private___ticketing__main__container__1
  {
    padding: 50px 20px;
  }
}